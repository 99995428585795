/* Instructions */
.body {
  background-color: white;
}
.instr-title {
  font-size: 30pt;
  font-weight: bold;
  text-align: center;
}

.attr-td {
  text-align: left;
  font-size: 1.3em;
}

/* INdex */
.section-sm {
    margin-top: 20px;
    margin-bottom: 20px;
    min-height: 500px;
    padding: 0.5rem;
}


.fade-loader {
    display: block;
    margin: 0 auto;
    border-color: red;
    position: fixed;
    top: 35%;
    left: 48%;
}

.sync-loader {
    display: block;
    margin: 0 auto;
    border-color: red;
    position: fixed;
    top: 5%;
    right: 5%;
}

.pagination {
    text-align: end;
    margin-right: 5em
}

.instr {
    margin-bottom: 20px;
  }

/* FONTS */
.instr-h1 {
    font-size: 2.3em;
    text-align: justify;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
}

.instr-h2 {
    font-size: 1.8em;
    text-align: justify;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
}

.instr-h3 {
    font-size: 1.37em;
    text-align: justify;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
}

.instr-h4 {
    font-size: 1.3em;
    text-align: justify;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
}

.instr-h5 {
    font-size: 1.13em;
    text-align: justify;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
}

.instr-h6 {
    font-size: .97em;
    text-align: justify;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
}

/* USERFORM */
form {
    max-width: 500px;
    margin: 0 auto;
}

/* VIsual pattern */
.pressSpace {
    position: fixed;
    top: 40%;
    left: 45%;
    text-align: center;
    transform: translate(-40%, -40%)
}